body {
  margin: 0px;
  padding: 0px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: rgb(255, 61, 142);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(27, 27, 27);
  min-height: 100vh;
}

#root {
  background-color: rgb(253, 255, 240);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  width: 90%;
}


h1 {
  padding-left: 5vw;
  font-size: 3.8vw;
  margin: 0px;
  border-bottom: .3vw solid rgb(255, 61, 142);
}

h2 {
  font-size: 3vw;
  padding-left: 2vw;
  color: rgb(255, 61, 142);
}

p {
  font-size: 1.6vw;
  padding-left: 1vw;
  padding-right: 1vw;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0px;
}

@media (max-width: 900px) {

  #root {
    flex-direction: column;
    width: 100%;
  }

  h1 {
    font-size: 10vw;
    text-align: center;
    padding-left: 0vw;
    border-bottom: 1vw solid rgb(255, 61, 142);
  }

  p {
    font-size: 5vw;
  }
}